import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../components/Button"
import { Link } from 'gatsby'

const WorkPiece = ({children, title, bg, bgHex, buttonTo}) => {
    const renderStaticImage = () => {
        if (title === "Zactly") {
            return <StaticImage
            style={{
            gridArea: "1/1",
            height: '100%',
            }}
            layout="fullWidth"
            placeholder="blurred"
            aspectRatio={5 / 3}
            className="rounded-tl-xl rounded-tr-xl"
            alt=""
            src="../images/freelance/zactly.png"
            formats={["auto", "webp", "avif"]}
        />;
        } else if (title === "The Pass") {
            return <StaticImage
            style={{
            gridArea: "1/1",
            height: '100%',
            }}
            layout="fullWidth"
            placeholder="blurred"
            aspectRatio={5 / 3}
            className="rounded-tl-xl rounded-tr-xl"
            alt=""
            src="../images/freelance/thepass.jpg"
            formats={["auto", "webp", "avif"]}
        />;
        } else if (title === "StoriiCare") {
            return <StaticImage
            style={{
            gridArea: "1/1",
            height: '100%',
            }}
            layout="fullWidth"
            placeholder="blurred"
            aspectRatio={5 / 3}
            className="rounded-tl-xl rounded-tr-xl"
            alt=""
            src="../images/freelance/storii.jpg"
            formats={["auto", "webp", "avif"]}
        />;
        } else if (title === "St Marks Place") {
            return <StaticImage
            style={{
            gridArea: "1/1",
            height: '100%',
            }}
            layout="fullWidth"
            placeholder="blurred"
            aspectRatio={5 / 3}
            className="rounded-tl-xl rounded-tr-xl"
            alt=""
            src="../images/freelance/stm.jpg"
            formats={["auto", "webp", "avif"]}
        />;
        } else if (title === "Jim Taylor") {
            return <StaticImage
            style={{
            gridArea: "1/1",
            height: '100%',
            }}
            layout="fullWidth"
            placeholder="blurred"
            aspectRatio={5 / 3}
            className="rounded-tl-xl rounded-tr-xl"
            alt=""
            src="../images/freelance/jt.jpg"
            formats={["auto", "webp", "avif"]}
        />;
        }
    }
    return (
        <div className={'rounded-xl shadow-lg text-white'} style={{minHeight: '260px'}}>
            <div className="rounded-tl-xl rounded-tr-xl">
                <Link to={buttonTo}>
                    <div className="rounded-tl-xl rounded-tr-xl" style={{ display: "grid", height: '100%', background: '#' + bgHex + ''}}>
                        {renderStaticImage()}
                        <div
                            style={{
                            gridArea: "1/1",
                            position: "relative",
                            placeItems: "center",
                            display: "grid",
                            backgroundImage: 'linear-gradient(to bottom, transparent, #' + bgHex + ')',
                            backgroundRepeat: 'no-repeat',
                            height: 'auto'
                            }}
                        >
                            <div className="h-auto"></div>
                        </div>
                    </div>
                </Link>
            </div>

            <div className={'p-10 rounded-bl-xl rounded-br-xl bg-gradient-to-b from-' + bg + '-900 to-' + bg + '-500 '}>
                <Link to={buttonTo}><h2 className="text-lg font-bold text-white hover:text-gray-300 transition-colors duration-200 transform">{title}</h2></Link>

                <div className="max-w-prose py-8 text-gray-200 text-sm">
                    <p>{children}</p>
                </div>

                <div className="flex">
                    <div className="ml-auto">
                        <Button label="Read more" destination={buttonTo} colour={bg} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkPiece