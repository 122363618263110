import * as React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import WorkPiece from "../components/WorkPiece"

// markup
const Freelance = () => {
  return (
      <Layout>
        <div>
        <Container showFooter colour="0d1120" title="Freelance work">
          <p className="mb-12 max-w-prose text-accent-text">Below are a few pieces of selected freelance work.</p>

          <div className="grid md:grid-cols-2 gap-12">
            <div>
              <WorkPiece buttonTo="zactly" title="Zactly" bg="purple" bgHex="4a4787">
              A tool for product designers to keep track of assumptions made about your product and users...
              </WorkPiece>
            </div>
            <div>
              <WorkPiece buttonTo="the-pass" title="The Pass" bg="green" bgHex="436b6d">
              In this project I worked with another designer to build their bespoke Wordpress site...
              </WorkPiece>
            </div>
            <div>
              <WorkPiece buttonTo="storiicare" title="StoriiCare" bg="yellow" bgHex="D95100">
              My work with Storii required creating a style guide and branding guidelines for multiple...
              </WorkPiece>
            </div>
            <div>
              <WorkPiece buttonTo="st-marks-place" title="St Marks Place" bg="ink" bgHex="43566D">
              Working with another designer, this job required a build with a bespoke template...
              </WorkPiece>
            </div>
            <div>
              <WorkPiece buttonTo="jim-taylor" title="Jim Taylor" bg="purple" bgHex="4a4787">
              Working with another designer, a Wordpress build with a bespoke template was required...
              </WorkPiece>
            </div>
          </div>
        </Container>
        </div>
      </Layout>
  )
}

export default Freelance