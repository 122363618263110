import * as React from "react"
import { Link } from 'gatsby'

const Button = ({label, destination, colour}) => {
    return (
        <Link to={destination}>
            <button className={'p-2 pl-5 pr-5 bg-transparent border-2 border-white-500 text-white-500 rounded-xl font-bold transition-colors duration-200 transform hover:bg-white hover:text-' + colour + '-900 focus:border-2 shadow-lg'}>{label}</button>
        </Link>
    )
}

export default Button